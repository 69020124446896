import React from "react";
import { getLink } from "../links-configuration";

import { useLocale } from "context/locale";
import { useIntl } from "react-intl";
import { Col, Row } from "react-bootstrap";
import Button from "components/ButtonV2/ButtonV2";

const NextStep = ({ showTeslaConfigurator, sendEmail, printOffer }) => {
  const { formatMessage } = useIntl();
  const { locale } = useLocale();
  return (
    <div>
      <Row>
        <Col
          sm={12}
          md={12}
          lg={12}
          xl={12}
          xxl={12}
          className="next-step-wrapper">
          <h3 className="text-next-step">
            {formatMessage({
              id: "publicleasing.title.next-step",
            })}
          </h3>
          <div className="btn-next-step-wrapper">
            {showTeslaConfigurator && (
              <div className="mr-3">
                <Button
                  title={formatMessage({
                    id: "publicleasing.button.configure-tesla",
                  })}
                  type="primary"
                  onClick={() => {
                    window.open(
                      getLink(locale, "TESTLA_CONFIGURATOR"),
                      "_blank"
                    );
                  }}
                />
              </div>
            )}
            <div className="ltq-config">
              <Button
                title={formatMessage({
                  id: "publicleasing.button.contact-us",
                })}
                type="secondary"
                onClick={printOffer}
              />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

NextStep.propTypes = {};

export default NextStep;
