import axios from "axios";

export const SASS_DO_ACCESS_TOKEN = "__sass_do_access_token";

const getLTQAPIInstance = () => {
  return axios.create({
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json; charset=utf-8",
      Authorization: "0ED49377-D53B-4CAE-90DE-70F8B8358A1E"
    },
    timeout: 30000,
    baseURL: process.env.REACT_APP_LTQ_API
  })
}

const getInstance = (includedAccessToken = true, navigate) => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json; charset=utf-8",
  };

  const instance = axios.create({
    headers,
    timeout: 30000,
    baseURL: process.env.REACT_APP_API,
    withCredentials: includedAccessToken,
  });

  // Add a request interceptor
  instance.interceptors.request.use(
    function (config) {
      if (includedAccessToken) {
        const accessToken = localStorage.getItem(SASS_DO_ACCESS_TOKEN);
        config.headers.Authorization = `Bearer ${accessToken}`;
      }

      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  // Add a response interceptor
  instance.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    async function (error) {
      const errorAsStringObject = JSON.stringify(error);
      const errorAsObject = JSON.parse(errorAsStringObject);

      const originalRequest = error.config;
      if (errorAsObject.message === "Network Error") {
        const errorMessage = ` ${errorAsObject.message} - Check your Internet Connection...`;
        window.location.href = `/#/error?status=${errorMessage}`;
      }
      if (error.response.status === 403 && !originalRequest._retry) {
        originalRequest._retry = true;
        const accessToken = Promise.resolve(Date.now());
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + accessToken;
        return instance(originalRequest);
      }
      if (error.response.status === 401) {
        const accessToken = await getSessionToken();
        error.response.config.headers["Authorization"] =
          "Bearer " + accessToken;
        return axios(error.response.config);
      }

      return Promise.reject(error);
    }
  );

  return instance;
};

export async function getSessionToken() {
  const rs = await getInstance(false).get("api/V1/GETSessionToken");
  const { data } = rs;
  const accessToken = data["session token"];
  if (accessToken) {
    localStorage.setItem(SASS_DO_ACCESS_TOKEN, accessToken);
    return accessToken;
  } else {
    // eslint-disable-next-line no-throw-literal
    throw {
      error: "ACCESS_TOKEN_NOT_FOUND",
      message: "Access token not found.",
    };
  }
}

export async function getOfferPDF(OEM, model, listingPrice, downPayment, residualValue, term, mileage, nomIntRate, effectiveRate, leasingratetotal, legalfee, handlingfee) {
  const country = "CH"
  try {
    return await getLTQAPIInstance()
      .post(
        "lc_carbone_offer",
        {
          OEM,
          model,
          listingPrice,
          downPayment,
          residualValue,
          term,
          mileage,
          nomIntRate,
          effectiveRate,
          leasingratetotal,
          legalfee,
          handlingfee,
          country
        }
      )
      .then(
        (response) => {
          return response.data;
        }
      )
      .catch(
        function(error) {
          if (error.response) {
            window.location.href = `/#/error?status=${error.response.status}`;
            console.error("error.response.status:", error.response.status);
          }
        }
      );
  }
  catch (err) {
    console.error(err);
  }
}

export function getInitialValues(Model, customer, isNew, prospectID, isInventory, options) {
  const isUsed = !isNew;
  const params = {
    Model,
    customer,
    isUsed,
    isInventory,
    options
  };

  if (prospectID) {
    params['uuid'] = prospectID;
  }

  return getInstance()
    .get("api/V1/GETInitialValues", {params})
    .catch(function (error) {
      if (error.response) {
        console.error("error.response.status:", error.response.status);
        window.location.href = `/#/error?status=${error.response.status}`;
      }
    });
}

export function getOEMs() {
  return getInstance()
    .get("api/V1/GEToems")
    .catch(function (error) {
      if (error.response) {
        window.location.href = `/#/error?status=${error.response.status}`;
        console.error("error.response.status:", error.response.status);
      }
    });
}

export function postCustomerData(person, contract, financials, beneficial, prospect) {
  return getInstance()
    .post("api/V1/POSTCustomerData", {
      person,
      contract,
      financials,
      beneficial,
      prospect
    })
    .catch(function (error) {
      if (error.response) {
        window.location.href = `/#/error?status=${error.response.status}`;
        console.error("error.response.status:", error.response.status);
      }
    });
}

export function postBusinessData(contract, company, person, financials, beneficialOwner, prospect) {
  return getInstance()
    .post("api/v1/POSTBusinessData", {
      contract,
      company,
      person,
      financials,
      beneficialOwner,
      prospect
    })
    .catch(function (error) {
      if (error.response) {
        window.location.href = `/#/error?status=${error.response.status}`;
        console.error("error.response.status:", error.response.status);
      }
    });
}

export function getContractData(contractID) {
  return getInstance()
    .get("api/V1/GETContractData", {
      params: {
        contractID,
      },
    })
    .catch(function (error) {
      if (error.response) {
        window.location.href = `/#/error?status=${error.response.status}`;
        console.error("error.response.status:", error.response.status);
      }
    });
}

export function postCustomerConsent(contractID) {
  return getInstance()
    .post(`api/V1/POSTCustomerConsent?contractID=${contractID}`)
    .catch(function (error) {
      if (error.response) {
        window.location.href = `/#/error?status=${error.response.status}`;
        console.error("error.response.status:", error.response.status);
      }
    });
}

export function postBusinessConsent(contractID) {
  return getInstance()
    .post(`api/v1/POSTBusinessConsent?contractID=${contractID}`)
    .catch(function (error) {
      if (error.response) {
        window.location.href = `/#/error?status=${error.response.status}`;
        console.error("error.response.status:", error.response.status);
      }
    });
}

export function getCountryByType(value) {
  return getInstance()
    .get(`api/v1/GETcountrybyType?requestType="${value}"`)
    .catch(function (error) {
      if (error.response) {
        window.location.href = `/#/error?status=${error.response.status}`;
        console.error("error.response.status:", error.response.status);
      }
    });
}

export function getCounty(value) {
  return getInstance()
    .get(`api/v1/countyList?ISOcountryCode=${value}`)
    .catch(function (error) {
      if (error.response) {
        window.location.href = `/#/error?status=${error.response.status}`;
        console.error("error.response.status:", error.response.status);
      }
    });
}

export function getCountry() {
  return getInstance()
    .get(`api/V1/GETCountryData`)
    .catch(function (error) {
      if (error.response) {
        window.location.href = `/#/error?status=${error.response.status}`;
        console.error("error.response.status:", error.response.status);
      }
    });
}

export function getLanguageFiles(countryCode) {
  return getInstance()
    .get(`api/v1/languagefiles?countrycode=${countryCode}`)
    .catch(function (error) {
      if (error.response) {
        window.location.href = `/#/error?status=${error.response.status}`;
        console.error("error.response.status:", error.response.status);
      }
    });
}

export function getTerm(lang) {
  return getInstance()
    .get(`api/v1/agb?language=${lang}`)
    .catch(function (error) {
      if (error.response) {
        window.location.href = `/#/error?status=${error.response.status}`;
        console.error("error.response.status:", error.response.status);
      }
    });
}

export function getDebugCalculator() {
  return getInstance()
    .get("v1/GETdebugCalculator")
    .catch(function (error) {
      if (error.response) {
        window.location.href = `/#/error?status=${error.response.status}`;
        console.error("error.response.status:", error.response.status);
      }
    });
}

export function thankYouData(txNum) {
  return getInstance()
    .post("api/v1/thankYou", {
      txNum: txNum,
    })
    .catch(function (error) {
      if (error.response) {
        window.location.href = `/#/error?status=${error.response.status}`;
        console.error("error.response.status:", error.response.status);
      }
    });
}

export function getProspectData(uuid) {
  const params = {
    params: {
      uuid: uuid,
      prospectLink: "",
    },
  };
  return getInstance()
    .get("api/v1/getProspectData", params)
    .catch(function (error) {
      if (error.response) {
        window.location.href = `/#/error?status=${error.response.status}`;
        console.error("error.response.status:", error.response.status);
      }
    });
}
/* export function autoComplete(fullAddress = "", countryCode = "") {
  return getInstance().post(
    `api/v1/googleAutoComplete?fullAddress=${fullAddress}&countryCode=${countryCode}`
  );
}

export function autoCompleteDetail(placeId = "") {
  return getInstance().get(
    `api/v1/googleAutoCompleteDetail?placeID=${placeId}`
  );
} */

export function intrumAutocomplete(fullAddress) {
  return getInstance().post("api/v1/intrumautocomplete", {
    fullAddress,
  });
}
