import React, { useState } from "react";
import ButtonDirection from "./ButtonDirection";
import ShortInfo from "./ShortInfo";
import { useMediaQuery } from "react-responsive";
import { RESPONSIVE } from "constant";
import ModalCta from "./ModalCta";
import "./cta.css";
import { useIntl } from "react-intl";
import { getGridSystemForStickyCta } from "screen/LeasingFormPublic/leasing-engine";
import useWindowSize from "hooks/useWindowSize";

function StickyCta({
  model,
  price,
  leasePrice,
  subTitle,
  nomInterest,
  effInterest,
  currency,
  border,
  prePayment,
  currMonth,
  currKm,
  srcImg,
  currResidual,
  setIsOpenModalCta,
  isOpenModalCta,
  sendEmail,
  printOffer,
  showTeslaConfigurator,
}) {
  const [show, setShow] = useState(false);
  const isSmallSize = useMediaQuery({ maxWidth: RESPONSIVE.MAX_WIDTH_SMALL });
  const { formatMessage } = useIntl();
  const [width, height] = useWindowSize();

  return (
    <div
      className="cta-sticky2"
      style={{ gridTemplateColumns: getGridSystemForStickyCta(width) }}>
      <div className="space-sticky-cta"></div>
      <div className="col-first-sticky-cta for-button">
        <div className="btn-wrapper">
          <ButtonDirection
            direction="up"
            onClick={() => {
              setShow(true);
              setIsOpenModalCta(true);
              document.body.style.overflow = "hidden";
            }}
          />
        </div>
      </div>
      <div className="space-sticky-cta"></div>
      <div className="col-second-sticky-cta">
        <ShortInfo
          price={price}
          leasePrice={leasePrice}
          subTitle={formatMessage({
            id: "publicleasing.text.cta-leasingrate",
          })}
          nomInterest={nomInterest}
          effInterest={effInterest}
          currency={currency}
        />
      </div>
      <div className="space-sticky-cta for-line"></div>
      <div className="space-sticky-cta col-sixth"></div>
      <div className="col-third-sticky-cta">
        {!isSmallSize && (
          <ShortInfo
            price={price}
            nomInterest=""
            effInterest=""
            border={false}
            currency={currency}
          />
        )}
      </div>
      {show && (
        <div className="modal2">
          <div className="modal-dialog-custom2">
            <div className="d-flex justify-content-center">
              {
                <ModalCta
                  value={show}
                  func={setShow}
                  model={model}
                  subTitle={subTitle}
                  price={price}
                  leasePrice={leasePrice}
                  prePayment={prePayment}
                  currMonth={currMonth}
                  currKm={currKm}
                  currency={currency}
                  nomInterest={nomInterest}
                  effInterest={effInterest}
                  srcImg={srcImg}
                  currResidual={currResidual}
                  sendEmail={sendEmail}
                  printOffer={printOffer}
                  isOpenModalCta={isOpenModalCta}
                  setIsOpenModalCta={setIsOpenModalCta}
                  showTeslaConfigurator={showTeslaConfigurator}
                />
              }
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default StickyCta;
