import Button from "components/ButtonV2/ButtonV2";
import { format } from "currency-formatter";
import React, { Fragment } from "react";
import { Col, Row } from "react-bootstrap";
import { useIntl } from "react-intl";
import { currKmData } from "../defaultData";
import {
  calcFinalValue,
  getOemValueByMileageAndMonth,
} from "../leasing-engine";
import NextStep from "../NextStep";

const MileageInformation = ({
  mileageViewRef,
  selectedMileage,
  onMileageChange,
  nomInterest,
  currMonth,
  prePayment,
  price,
  currResidual,
  finalValue,
  residualValuesOEM,
  sendEmail,
  printOffer,
  showTeslaConfigurator,
}) => {
  const { formatMessage } = useIntl();

  const getStatus = (mileage) => {
    const oemValue = getOemValueByMileageAndMonth(
      residualValuesOEM,
      mileage,
      currMonth
    );
    if (oemValue <= 0) return "disabled";

    return selectedMileage && selectedMileage === mileage ? "active" : "idle";
  };

  const getSubtitle = (currKmData) => {
    const mileage = currKmData.value;
    if (mileage === selectedMileage) return "-";

    const oemValue = getOemValueByMileageAndMonth(
      residualValuesOEM,
      mileage,
      currMonth
    );
    if (oemValue <= 0)
      return formatMessage({ id: "publicleasing.button.not-available" });

    const newMaxResidual = oemValue * price;
    const res = calcFinalValue(
      nomInterest,
      currMonth,
      prePayment,
      price,
      newMaxResidual
    );
    const diff = res - finalValue;

    if (diff === 0)
      return formatMessage({ id: "publicleasing.button.inclusive" });

    return `${diff < 0 ? "-" : "+"} ${format(Math.abs(diff), {
      code: "CHF",
      precision: 0,
    })} ${formatMessage({ id: "publicleasing.button.monthly" })}`;
  };

  return (
    <Fragment>
      <div
        className="block-content mileage-information-wrapper"
        ref={mileageViewRef}>
        <Row className="mb-4">
          <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
            <p className="second-title">
              {formatMessage({ id: "publicleasing.title.mileage" })}
            </p>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            xxl={12}
            className="button-wrapper">
            {currKmData.map((item) => (
              <Button
                title={item.title}
                subTitle={getSubtitle(item)}
                value={item.value}
                status={getStatus(item.value)}
                size="big"
                onClick={() => onMileageChange(item.value)}
                key={item.value}
              />
            ))}
          </Col>
        </Row>
      </div>
      <NextStep
        showTeslaConfigurator={showTeslaConfigurator}
        sendEmail={sendEmail}
        onClick={printOffer}
      />
    </Fragment>
  );
};

export default MileageInformation;
